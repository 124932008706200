import React from 'react'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import {
  MdCheckCircle,
  MdFavorite,
  MdPlusOne,
  MdStar,
} from 'react-icons/md'
import { BiNetworkChart } from 'react-icons/bi'
import { FiTarget } from 'react-icons/fi'
import { IoIosPeople } from 'react-icons/io'
import { VscMegaphone } from 'react-icons/vsc'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Helmet } from 'react-helmet'
import ogImage from '../images/og_image.jpeg'

const bereiche = [
  {
    icon: null,
    title: 'Diversität',
    description:
      'Die Inklusion von Minderheiten aller Art geht mit dem Abbau von Vorurteilen einher. Wir setzen uns gegen jegliche Ausgrenzungen ein und kämpfen für ein Recht auf wahre Teilhabe in Entscheidungsstrukturen.',
    meta: ['Diversität', 'Inklusion', 'Vorurteile'],
  },
  {
    icons: null,
    title: 'Chancengerechtigkeit',
    description:
      'Durch die ungerechte Verteilung von Chancen bleiben unserem Land viele visionäre, briliante und fleißige Menschen vorenthalten. Jeder Mensch verdient dieselben Chancen um sich zu verwirklichen und der Gesellschaft sowohl im beruflichen als auch im politischen beitragen zu können.',
    meta: ['Chancen', 'Gerechtigkeit'],
  },
  {
    icons: null,
    title: 'Österreich-Bewusstein',
    description:
      'Wir alle gehören, trotz Unterschieden, ein und derselben Gemeinschaft an - daran lässt sich nicht rütteln. Wir alle teilen eine Liebe für unsere Heimat und sind dadurch miteinander verbunden. Sovie ist es wichtig diesen Bund zu stärken und jedem Bürger zu zeigen wie viel Österreich doch in uns allen steckt.',
    meta: ['Österreich', 'Gemeinschaft', 'Liebe'],
  },
]

function BereicheBox({
  description = '',
  meta = [],
  title = '',
  icons = null,
  children = null,
}) {
  return (
    <Box borderWidth="1px" borderRadius="md" overflow="hidden">
      <Box p="6">
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {title}
        </Box>
        <Box mt="1" fontWeight="light" as="p" lineHeight="tight">
          {description}
        </Box>
        {meta.length > 0 && (
          <Box d="flex" flexWrap="wrap" mt="3" alignItems="baseline">
            {meta.map((elm, i) => (
              <Badge
                key={i}
                borderRadius="full"
                px="2"
                mr="2"
                colorScheme="red"
              >
                {elm}
              </Badge>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const IndexPage = () => {
  return (
    <>
      <Helmet
        title="Sovie - die Stimme der Vielfalt in Österreich"
        meta={[
          {
            name: `description`,
            content: `Sovie ist die sozialdemokratische Gleichstellungs- und Gleichberechtigungs NGO. Wir stehen für die unabdingbare Gleichstellung und Gleichberechtigung von allen Menschen in Österreich.`,
          },
          {
            property: `og:title`,
            content: `Sovie - die Stimme der Vielfalt in Österreich`,
          },
          {
            property: `og:url`,
            content: `https://www.sovie.or.at/`,
          },
          {
            property: `og:locale`,
            content: `de_DE`,
          },
          {
            property: `og:description`,
            content: `Sovie ist die sozialdemokratische Gleichstellungs- und Gleichberechtigungs NGO. Wir stehen für die unabdingbare Gleichstellung und Gleichberechtigung von allen Menschen in Österreich.`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            property: `og:site_name`,
            content: `SoVie Österreich`,
          },
          {
            property: `article:publisher`,
            content: `https://www.facebook.com/SoVie-%C3%96sterreich-114495926645116/`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: `sovie.or.at`,
          },
          {
            name: `twitter:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            name: `twitter:title`,
            content: `Sovie - die Stimme der Vielfalt in Österreich`,
          },
          {
            name: `twitter:description`,
            content: `Sovie ist die sozialdemokratische Gleichstellungs- und Gleichberechtigungs NGO. Wir stehen für die unabdingbare Gleichstellung und Gleichberechtigung von allen Menschen in Österreich.`,
          },
        ]}
      ></Helmet>
      <Box>
        <Box as="section" pt="12rem" pb="5rem">
          <Box
            maxW="75rem"
            ml="auto"
            mr="auto"
            pl="1.5rem"
            pr="1.5rem"
          >
            <Box textAlign="center">
              <Heading
                as="h1"
                maxW="680px"
                ml="auto"
                mr="auto"
                fontSize={['3xl', '4xl', '6xl']}
                lineHeight="1.2"
              >
                Sovie; die Stimme der{' '}
                <Box as="span" color="red.600">
                  Vielfalt in Österreich
                </Box>
              </Heading>
              <Text
                fontSize={['md', 'lg', 'xl']}
                ml="auto"
                mr="auto"
                maxW="560px"
                mt="1.5rem"
                color="gray"
                opacity="0.7"
              >
                Sovie ist die sozialdemokratische Gleichstellungs- und
                Gleichberechtigungs NGO. Wir stehen für die
                unabdingbare Gleichstellung und Gleichberechtigung von
                allen Menschen in Österreich.
              </Text>
              <Box mt="2.5rem">
                <Button
                  color="red.500"
                  onClick={() => scrollTo('#sprachrohr')}
                  size={useBreakpointValue(['sm', 'md'])}
                >
                  Mehr erfahren
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box as="section" pt="7.5rem" pb="7.5rem" id="sprachrohr">
          <Box
            maxW="75rem"
            ml="auto"
            mr="auto"
            pl="1.5rem"
            pr="1.5rem"
          >
            <Box textAlign="center">
              <Heading
                as="h2"
                ml="auto"
                mr="auto"
                fontSize={['3xl', '4xl', '5xl']}
                lineHeight="1.24"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box as="span">Sprachrohr für alle</Box>
                  <Icon as={VscMegaphone} ml="3" />
                </Flex>
              </Heading>
              <Text
                fontSize={['md', 'lg', 'xl']}
                ml="auto"
                mr="auto"
                mt="3.5rem"
                opacity="0.7"
              >
                Wir sind das Sprachrohr für alle Menschen, die
                tagtäglich von Ausgrenzung betroffen sind. Jeder
                Mensch verdient ein würdevolles und selbstbestimmtes
                Leben unabhängig von der ethnischen oder nationalen
                Herkunft, Hautfarbe, religiösen Weltanschauung,
                Geschlecht, sexuelle Orientierung, Körpergröße,
                sozialen Status uva.
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="section" pt="7.5rem" pb="7.5rem" bgColor="gray.100">
          <Box
            maxW="75rem"
            ml="auto"
            mr="auto"
            pl="1.5rem"
            pr="1.5rem"
          >
            <Box textAlign="center">
              <Heading
                as="h2"
                ml="auto"
                mr="auto"
                fontSize={['3xl', '4xl', '5xl']}
                lineHeight="1.24"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box as="span">Wir sind die Gesellschaft</Box>
                  <Icon as={IoIosPeople} ml="3" />
                </Flex>
              </Heading>
              <Text
                fontSize={['md', 'lg', 'xl']}
                ml="auto"
                mr="auto"
                mt="3.5rem"
                opacity="0.7"
              >
                Wir sind nicht Teil der Gesellschaft. Wir sind die
                Gesellschaft. Wir sind die Stimme für ein vielfältiges
                Österreich. Derzeit spiegelt sich die
                Bevölkerungsstruktur in den Entscheidungsstrukturen
                nicht wieder. Wir wollen echte Vielfalt, weg von den
                Lippenbekenntnissen hin zur echten Teilhabe. Wir
                wollen, dass jeder  Bürger dieses Landes über die
                Möglichkeit verfügt politisch mitreden zu können. Wir
                wollen einen Rahmen schaffen indem niemand seine
                Identität leugnen muss um ein vollwertiges Mitglied
                unserer Gesellschaft zu sein. Um den Weg dorthin zu
                erleichtern benötigen wir Sovie. 
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="section" pt="7.5rem" pb="7.5rem">
          <Box
            maxW="75rem"
            ml="auto"
            mr="auto"
            pl="1.5rem"
            pr="1.5rem"
          >
            <Box textAlign="center">
              <Heading
                as="h2"
                ml="auto"
                mr="auto"
                fontSize={['3xl', '4xl', '5xl']}
                lineHeight="1.24"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box as="span">Unser Traum</Box>
                  <Icon as={MdStar} ml="3" />
                </Flex>
              </Heading>
              <Text
                fontSize={['md', 'lg', 'xl']}
                ml="auto"
                mr="auto"
                mt="3.5rem"
                color="gray"
                opacity="0.7"
              >
                Unsere Leidenschaft ist der Kampf um ein Österreich,
                das sozial gerecht ist. Ein Österreich, in dem jedem
                Menschen dieselben Chancen geboten werden und in dem
                jeder Mensch sich selbstbestimmt dazu entscheiden kann
                diese zu ergreifen und in Freiheit zu leben. In
                unserer Weltvorstellung stehen wir solidarisch
                füreinander ein und unterstützen uns gegenseitig in
                unseren Bestreben. 
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          as="section"
          pt="7.5rem"
          pb="7.5rem"
          bgColor="red.600"
          color="white"
        >
          <Box
            maxW="75rem"
            ml="auto"
            mr="auto"
            pl="1.5rem"
            pr="1.5rem"
          >
            <Box textAlign="center">
              <Heading
                as="h2"
                ml="auto"
                mr="auto"
                fontSize={['3xl', '4xl', '5xl']}
                lineHeight="1.24"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box as="span">Unsere Ziele</Box>
                  <Icon as={FiTarget} ml="3" />
                </Flex>
              </Heading>
              <Text
                fontSize={['md', 'lg', 'xl']}
                ml="auto"
                mr="auto"
                mt="3.5rem"
                opacity="1"
              >
                Wir alle sind Mitglieder derselben Familie (oder: wir
                alle gehören zu Österreich.). Daran wird sich nichts
                ändern, egal ob es Stimmen gibt, die dagegen sprechen
                oder nicht. Uns ist es wichtig den Zusammenhalt in
                Österreich zu fördern und zu stärken.  Das
                Zugehörigkeitsgefühl in den Menschen zu regen und zu
                versichern, dass jeder so akzeptiert wird wie er ist.
                Um diese Werte auch in der Politik umsetzen zu können
                fordern wir echte Teilhabe am staatlichen und
                politischen Geschehen. Bloße Mitsprache genügt hier
                nicht.  Es ist Zeit für eine indiskutable,
                selbstverständliche Akzeptanz aller Minderheiten. Es
                ist Zeit für wahre Mitgestaltungsmöglichkeiten. 
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="section" pt="7.5rem" pb="7.5rem">
          <Box
            maxW="75rem"
            ml="auto"
            mr="auto"
            pl="1.5rem"
            pr="1.5rem"
          >
            <Box>
              <Heading
                as="h2"
                ml="auto"
                mr="auto"
                fontSize={['3xl', '4xl', '5xl']}
                lineHeight="1.24"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box as="span">Tätigkeitsbereiche</Box>
                  <Icon as={BiNetworkChart} ml="3" />
                </Flex>
              </Heading>
              <Box mt="4rem">
                <Flex
                  wrap="wrap"
                  m="-.5rem"
                  justifyContent={{
                    base: 'center',
                    md: 'space-around',
                  }}
                >
                  {bereiche.map((bereich, i) => (
                    <Box p=".5rem" flex="1 1 23.5rem" key={i}>
                      <BereicheBox
                        key={i}
                        title={bereich.title}
                        description={bereich.description}
                        meta={bereich.meta}
                      ></BereicheBox>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider
          colorScheme="red"
          maxW="15rem"
          ml="auto"
          mr="auto"
          pl="1.5rem"
          pr="1.5rem"
        ></Divider>
        <Box as="section" pt="7.5rem" pb="7.5rem">
          <Box
            maxW="75rem"
            ml="auto"
            mr="auto"
            pl="1.5rem"
            pr="1.5rem"
          >
            <Box>
              <Heading
                as="h2"
                ml="auto"
                mr="auto"
                fontSize={['3xl', '4xl', '5xl']}
                lineHeight="1.24"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box as="span">Werde ein Teil von uns</Box>
                  <Icon as={MdPlusOne} ml="3" />
                </Flex>
              </Heading>
              <Box mt="4rem">
                <Flex
                  wrap="wrap"
                  m="-.5rem"
                  justifyContent={{
                    base: 'center',
                    md: 'space-around',
                  }}
                >
                  <Box p=".5rem" flex="1 1 calc(22rem + 0.5rem)">
                    <Box
                      // maxW="23.5rem"^
                      borderWidth="1px"
                      borderRadius="md"
                      overflow="hidden"
                    >
                      <Box p="6">
                        <Heading
                          mt="1"
                          fontWeight="bold"
                          as="h3"
                          size="md"
                          lineHeight="tight"
                        >
                          Wer kann mitmachen?
                        </Heading>
                        <List spacing={3} mt="1.5rem">
                          <ListItem fontWeight="light">
                            <ListIcon
                              as={MdCheckCircle}
                              color="green.500"
                            />
                            <Box as="span">
                              Alle Menschen, die sich zu den
                              sozialdemokratischen Werten Freiheit,
                              Gleichheit, Gerechtigkeit und
                              Solidarität bekennen und für eine bunte,
                              durchmischte Gesellschaft der Vielfalt
                              eintreten.
                            </Box>
                          </ListItem>
                          <ListItem fontWeight="light">
                            <ListIcon
                              as={MdCheckCircle}
                              color="green.500"
                            />
                            <Box as="span">
                              Alle, die das Verbindende vor das
                              Trennende stellen. Alle Menschen die
                              sich einer Minderheit zugehörig fühlen
                              und sich in der Sozialdemokratie
                              engagieren wollen!
                            </Box>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  </Box>

                  <Box p=".5rem" flex="1 1 calc(22rem + 0.5rem)">
                    <Box
                      borderWidth="1px"
                      borderRadius="md"
                      overflow="hidden"
                    >
                      <Box p="6">
                        <Heading
                          as="h3"
                          mt="1"
                          fontWeight="bold"
                          size="md"
                          lineHeight="tight"
                        >
                          Mitglied werden
                        </Heading>
                        <List spacing={3} mt="1.5rem">
                          <ListItem fontWeight="light">
                            <ListIcon
                              as={MdFavorite}
                              color="green.500"
                            />
                            <Box as="span">
                              Du willst in deinem Land mitgestalten?
                            </Box>
                          </ListItem>
                          <ListItem fontWeight="light">
                            <ListIcon
                              as={MdFavorite}
                              color="green.500"
                            />
                            <Box as="span">
                              Du willst dich mit uns austauschen und
                              uns unterstützen?
                            </Box>
                          </ListItem>
                        </List>
                        <Text
                          fontSize={['md']}
                          ml="auto"
                          mr="auto"
                          maxW="100%"
                          mt="1.5rem"
                          fontWeight="light"
                        >
                          Unterstütze ein vielfältiges Österreich, für
                          uns und unsere Nachkommen, indem du Mitglied
                          wirst. Du kannst uns sowohl in unserer
                          Arbeit unterstützen als auch mit
                          finanziellen Mitteln ausstatten, damit wir
                          eine starke Stimme für ein vielfältiges
                          Österreich sein können. 
                        </Text>
                        <Flex mt="8">
                          <Button
                            as={GatsbyLink}
                            to="/mitglied-werden"
                            colorScheme="red"
                            size={useBreakpointValue(['sm', 'md'])}
                          >
                            Jetzt Mitglied werden
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default IndexPage
